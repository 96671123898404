import { useQuery } from '@tanstack/vue-query';
import { validatorFactory, validatorFactoryArray } from '@laam/lib/validator';
import {
	type RewardsHistoryResponse,
	RewardsHistorySchema,
	type RewardsIndexResponse,
	RewardsIndexSchema,
	type SignupRewardsResponse,
} from '~/types/Checkout.ts';
import * as Sentry from '@sentry/vue';
import { SignupRewardsSchema } from '~/types/Checkout.ts';
import { useLoganStore } from '~/stores/logan.ts';
import { isRewardsEnabled } from '~/utils/featureFlags.ts';

//------ Rewards Signup Api
const signupRewardsValidator =
	validatorFactory<SignupRewardsResponse>(SignupRewardsSchema);

export const useSignupRewardsQuery = () => {
	const LoganStore = useLoganStore();
	const enabled = computed(() => !LoganStore.isSignedIn);
	const { data, error, isLoading, isError, isFetching } = useQuery({
		queryKey: ['signup_rewards'],
		staleTime: Infinity,
		retry: 2,
		enabled,
		refetchOnWindowFocus: false,

		queryFn: async () => {
			try {
				const api = createApiInstance();
				const response = await api.get('/rewards/signup');

				if (response.status === 200) {
					try {
						return signupRewardsValidator.verify(response.data);
					} catch (e) {
						const err = new Error('Failed to verify rewards signup data');
						Sentry.captureException(err, (scope) => {
							scope.setContext('errors', {
								error: e,
							});
							return scope;
						});
						console.error('Failed to verify rewards signup data', e);
						throw err;
					}
				}
				const error = new Error('Failed to fetch rewards signup');
				Sentry.captureException(error, (scope) => {
					scope.setContext('response', response.data);
					return scope;
				});
				throw error;
			} catch (error: any) {
				console.error('Failed to fetch rewards signup', error);
				Sentry.captureException(error);
				throw new Error(error.message);
			}
		},
	});

	return {
		data,
		error,
		isLoading,
		isError,
		isFetching,
	};
};

//------ Rewards Index Api

const rewardsIndexValidator =
	validatorFactory<RewardsIndexResponse>(RewardsIndexSchema);

export const useRewardsIndexQuery = () => {
	const LoganStore = useLoganStore();
	const rewardsEnabled = isRewardsEnabled();

	const enabled = computed(
		() =>
			LoganStore.isSignedIn &&
			!!LoganStore.loganId &&
			!LoganStore.isHvc &&
			rewardsEnabled,
	);
	const { data, error, isLoading, isError, isFetching } = useQuery({
		queryKey: ['rewards_index'],
		staleTime: Infinity,
		retry: 2,
		enabled,
		refetchOnWindowFocus: false,

		queryFn: async () => {
			try {
				const api = createApiInstance();
				const response = await api.get(`/rewards/sum/${LoganStore.loganId}`);

				if (response.status === 200) {
					try {
						return rewardsIndexValidator.verify(response.data);
					} catch (e) {
						const err = new Error('Failed to verify rewards index data');
						Sentry.captureException(err, (scope) => {
							scope.setContext('errors', {
								error: e,
							});
							return scope;
						});
						console.error('Failed to verify rewards index data', e);
						throw err;
					}
				}
				const error = new Error('Failed to fetch rewards index');
				Sentry.captureException(error, (scope) => {
					scope.setContext('response', response.data);
					return scope;
				});
				throw error;
			} catch (error: any) {
				console.error('Failed to fetch rewards index', error);
				Sentry.captureException(error);
				throw new Error(error.message);
			}
		},
	});

	return {
		data,
		error,
		isLoading,
		isError,
		isFetching,
	};
};

//------ Rewards History Api

const rewardsHistoryValidator =
	validatorFactoryArray<RewardsHistoryResponse>(RewardsHistorySchema);

export const useRewardsHistoryQuery = () => {
	const LoganStore = useLoganStore();

	const enabled = computed(() => LoganStore.isSignedIn && !!LoganStore.loganId);
	const { data, error, isLoading, isError, isFetching } = useQuery({
		queryKey: ['rewards_history'],
		staleTime: Infinity,
		retry: 2,
		enabled,
		refetchOnWindowFocus: false,

		queryFn: async () => {
			try {
				const api = createApiInstance();
				const response = await api.get(
					`/rewards/history/${LoganStore.loganId}`,
				);

				// https:logan-sxbx2pi4bq-uc.a.run.app/rewards/history/185255
				if (response.status === 200) {
					try {
						return rewardsHistoryValidator.verify(response.data);
					} catch (e) {
						const err = new Error('Failed to verify rewards history data');
						Sentry.captureException(err, (scope) => {
							scope.setContext('errors', {
								error: e,
							});
							return scope;
						});
						console.error('Failed to verify rewards history data', e);
						throw err;
					}
				}
				const error = new Error('Failed to fetch rewards history');
				Sentry.captureException(error, (scope) => {
					scope.setContext('response', response.data);
					return scope;
				});
				throw error;
			} catch (error: any) {
				console.error('Failed to fetch rewards history', error);
				Sentry.captureException(error);
				throw new Error(error.message);
			}
		},
	});

	return {
		data,
		error,
		isLoading,
		isError,
		isFetching,
	};
};
